.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.jumbotron {
  background-image: url(https://s3-us-west-2.amazonaws.com/denverbicyclelobby.com/img/banner.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.swag-image {
  width: 120px;
}

.swag-media {
  margin-bottom: 16px;
}

#cycling-the-seasons{
  max-width: 300px;
}

.dbl-logo{
  border-radius: 6px;
}